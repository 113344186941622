<template>
    <div class="editPassword">
        <NavTab title="修改登录密码"></NavTab>
        <div class="editPassword-content">
            <van-form @submit="onSubmit">
                <van-field v-model.trim="formData.origin_password" type="password" name="originPassword" label="原密码"
                    placeholder="请输入密码" />
                <van-field v-model.trim="formData.new_password" type="password" name="newsPassword" label="新密码"
                    placeholder="请输入密码" />
                <van-field v-model.trim="conform_password" type="password" name="confirmPasswod" label="确认密码"
                    placeholder="请输入密码" />
                <div style="margin: 16px;">
                    <van-button block type="info" native-type="submit">确定</van-button>
                </div>
            </van-form>
        </div>
        <!-- 修改密码成功提示 -->
        <div class="message">
            <van-notify class="error" v-model="showError" type="success">
                <van-icon name="bell" style="margin-right: 4px;" />
                <span>密码错误！</span>
            </van-notify>
            <van-notify v-model="showMessge" type="success">
                <van-icon name="bell" style="margin-right: 4px;" />
                <span>修改密码成功！</span>
            </van-notify>

        </div>
    </div>
</template>

<script>
import NavTab from '@/components/Mobile/NavTab'
import MessagePoint from '@/components/Mobile/MessagePoint'
export default {
    name: 'EditPassword',
    components: {
        NavTab,
        MessagePoint
    },
    data() {
        return {
            formData: {
                origin_password: '',
                new_password: ''
            },
            //    确认密码
            conform_password: '',
            // 是否展示修改密码成功
            showMessge: false,
            showError: false, //提示密码是否错误
        };
    },

    mounted() {

    },

    methods: {
        // 表单验证
        async validateForm() {

            // 验证密码长度为8-16位，需包含字母和数字
            let reg = /^(?=.*[0-9\!@#\$%\^&\*])(?=.*[a-zA-Z]).{8,16}$/;
            let { origin_password, new_password } = this.formData;
            // console.log(123,patterns.test(new_password));
            if (origin_password === '') {
                return this.$dialog.alert({
                    message: '旧密码不能为空',
                });
            }
            else if (new_password === '') {
                return this.$dialog.alert({
                    message: '新密码不能为空',
                });
            }
            else if (this.conform_password === '') {
                return this.$dialog.alert({
                    message: '确认密码不能为空',
                });
            }
            //    else if(!reg.test(new_password))
            //    {
            //     return this.$dialog.alert({
            //    message: '密码需包含字母和数字,长度8到16位',
            //    });
            //    }
            else if (new_password !== this.conform_password) {
                return this.$dialog.alert({
                    message: '两次输入的密码不一致',
                });
            }
            //    密码验证错误
            //    else if(origin_password ){
            //     console.log(123);
            //    }
            //验证通过
            else {
                try {
                    //  调用修改密码接口
                    let res = await this.api.
                        postFormAPISM(
                            {
                                ...this.formData,
                            },
                            "/user/editloginpassword"
                        );
                    console.log(res);
                    //   登录密码错误
                    if (res.status === 401 || res.data.code === 'WRONG_PASSWORD_EXISTED_REQUIRE') {
                        this.showError = true;
                        setTimeout(() => {
                            this.showError = false;
                        }, 500);
                        return;
                    }
                    this.showMessge = true;
                    setTimeout(() => {
                        this.showMessge = false;
                    }, 500);
                    this.$router.back();
                    // 清空数据
                    // this.showError=false;
                    // this.showMessge=false;
                } catch (error) {
                    console.log(error);
                }

            }
        },
        // 提交表单
        onSubmit() {
            this.validateForm();
        }
    },
};
</script>

<style lang="less" scoped>
.editPassword {
    height: 100vh;
    background: #f5f5f5;

    &>.editPassword-content {
        margin-top: 20px
    }

    &>.message {
        &>.van-popup {
            position: fixed;
            width: 116px;
            padding: 4px 16px;
            border-radius: 50px;
            font-size: 12px;
            margin: 0 calc(50% - 50px);
            top: 20px;
            background-color: rgb(67, 122, 239);

            &>i {
                display: none;
            }
            &.error{
            background: rgb(243, 181, 181);
            color: red;
            width: 92px;
        }
        }
    }
}
</style>